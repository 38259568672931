<template>
  <div class="page">
    <top-bar></top-bar>
    <div class="main has-bar">
      <div class="m1">
        <div class="m1-name">客户姓名：{{infoData.name}}</div>
        <div class="m1-name">体检号：{{infoData.no}}</div>
      </div>
      <div class="m2">
        <div class="name">本次检查结果</div>
        <!-- <div class="name">本次检查共有{{infoData.count}}项异常项</div> -->
        <div class="pic">
          <img src="../assets/imgmain/pic.png" alt="">
        </div>
      </div>
      <div class="m3">
        <div class="item" @click="handleLink('zong')">
          <span>进入体检总结</span>
          <img src="../assets/imgmain/arrow-right.png" alt="">
        </div>
        <div class="item" @click="handleLink('jian')">
          <span>进入体检建议</span>
          <img src="../assets/imgmain/arrow-right.png" alt="">
        </div>
        <div class="item" @click="handleLink('list')">
          <span>进入体检详情</span>
          <img src="../assets/imgmain/arrow-right.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import api from '../api'
export default {
  data () {
    return {
      infoData: {
        name: '',
        no: '',
        count: ''
      }
    }
  },
  computed: {
    ...mapState(['name', 'no'])
  },
  methods: {
    handleLink (path) {
      this.$router.push({
        path,
        query: {
          no: this.$route.query.no
        }
      })
    },
    getInfo () {
      api.get('report/result/user_info', { serial_no: this.$route.query.no }).then(res => {
        if (res) {
          const data = res.data
          this.infoData = {
            name: data.person_name,
            no: data.serial_no,
            count: data.count
          }
        }
      })
    }
  },
  created () {
    this.infoData.name = this.name
    this.infoData.no = this.no
    this.infoData.count = 'N'
    this.getInfo()
  }
}
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  background: url("../assets/imgmain/bg.png");
  background-size: cover;
  background-position: top center;
  background-color: #12C77D;
  .m1 {
    padding: 36px 28px;
    height: 250px;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 800;
    background: url("../assets/imgmain/man.png");
    background-position: right top;
    background-size: 100% auto;
    .m1-name {
      padding-top: 36px;
    }
  }
  .m2 {
    height: 428px;
    padding: 80px;
    background-image: url("../assets/imgmain/box.png");
    background-position: top center;
    background-size: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .name {
      color: #00AF67;
      font-size: 17px;
      font-weight: 700;
    }
    .pic {
      width: 179px;
      height: 193px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .m3 {
    width: 660px;
    margin: 0 auto;
    .item {
      margin-top: 40px;
      padding: 0 60px;
      height: 94px;
      color: #00AF67;
      font-size: 18px;
      background-color: #fff;
      border-radius: 48px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        height: 36px;
        width: auto;
      }
    }
  }
}
</style>
